<template>
  <div class="modules-container">
    <el-card class="box-card">
      <template #header>
        <div class="clearfix">
          <span>模块管理</span>
        </div>
      </template>

      <el-row :gutter="20">
        <el-col :span="12">
          <div class="form-container">
            <h3>添加模块</h3>
            <el-form :model="newModule" label-width="100px">
              <el-form-item label="模块名">
                <el-input v-model="newModule.name"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="newModule.description"></el-input>
              </el-form-item>
              <el-button type="primary" @click="addModule">添加</el-button>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="form-container" v-if="editMode">
            <h3>编辑模块</h3>
            <el-form :model="editedModule" label-width="100px">
              <el-form-item label="模块名">
                <el-input v-model="editedModule.name"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="editedModule.description"></el-input>
              </el-form-item>
              <el-button type="primary" @click="updateModule">保存</el-button>
              <el-button @click="cancelEdit">取消</el-button>
            </el-form>
          </div>
        </el-col>
      </el-row>

      <div class="table-container">
        <h3>模块列表</h3>
        <el-table :data="modules" style="width: 100%;">
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="模块名"></el-table-column>
          <el-table-column prop="description" label="描述"></el-table-column>
          <el-table-column prop="created_at" label="创建时间"></el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" size="small" @click="editModule(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="deleteModule(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-divider></el-divider>

      <div class="skip-management">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="form-container">
              <h3>添加CMS类型</h3>
              <el-form :model="newSkip" label-width="100px">
                <el-form-item label="CMS类型名">
                  <el-input v-model="newSkip.name"></el-input>
                </el-form-item>
                <el-button type="primary" @click="addSkip">添加</el-button>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-container" v-if="editSkipMode">
              <h3>编辑CMS类型</h3>
              <el-form :model="editedSkip" label-width="100px">
                <el-form-item label="CMS类型名">
                  <el-input v-model="editedSkip.name"></el-input>
                </el-form-item>
                <el-button type="primary" @click="updateSkip">保存</el-button>
                <el-button @click="cancelSkipEdit">取消</el-button>
              </el-form>
            </div>
          </el-col>
        </el-row>

        <div class="table-container">
          <h3>CMS类型列表</h3>
          <el-table :data="skips" style="width: 100%;">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="name" label="CMS类型名"></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="text" size="small" @click="editSkip(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteSkip(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-divider></el-divider>

      <!-- 新增部分: awvs_api_settings 管理 -->
      <div class="awvs-api-management">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="form-container">
              <h3>添加 AWVS API 设置</h3>
              <el-form :model="newAwvsApi" label-width="100px">
                <el-form-item label="AWVS URL">
                  <el-input v-model="newAwvsApi.awvsUrl"></el-input>
                </el-form-item>
                <el-form-item label="API">
                  <el-input v-model="newAwvsApi.api"></el-input>
                </el-form-item>
                <el-button type="primary" @click="addAwvsApi">添加</el-button>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="form-container" v-if="editAwvsApiMode">
              <h3>编辑 AWVS API 设置</h3>
              <el-form :model="editedAwvsApi" label-width="100px">
                <el-form-item label="AWVS URL">
                  <el-input v-model="editedAwvsApi.awvsUrl"></el-input>
                </el-form-item>
                <el-form-item label="API">
                  <el-input v-model="editedAwvsApi.api"></el-input>
                </el-form-item>
                <el-button type="primary" @click="updateAwvsApi">保存</el-button>
                <el-button @click="cancelAwvsApiEdit">取消</el-button>
              </el-form>
            </div>
          </el-col>
        </el-row>

        <div class="table-container">
          <h3>AWVS API 设置列表</h3>
          <el-table :data="awvsApis" style="width: 100%;">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="awvsUrl" label="AWVS URL"></el-table-column>
            <el-table-column prop="api" label="API"></el-table-column>
            <el-table-column prop="created_at" label="创建时间"></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="text" size="small" @click="editAwvsApi(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteAwvsApi(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';

export default {
  name: 'ModulesView',
  setup() {
    // 模块管理逻辑
    const modules = ref([]);
    const newModule = ref({
      name: '',
      description: ''
    });
    const editedModule = ref({});
    const editMode = ref(false);

    const skips = ref([]);
    const newSkip = ref({ name: '' });
    const editedSkip = ref({});
    const editSkipMode = ref(false);

    const fetchModules = () => {
      axios.get('https://manage.onenike.com/api/getmodule.php').then(response => {
        modules.value = response.data;
      }).catch(error => {
        console.error(error);
        ElMessage.error('获取模块信息失败');
      });
    };

    const addModule = () => {
      if (!newModule.value.name || !newModule.value.description) {
        ElMessage.warning('模块名和描述不能为空');
        return;
      }
      axios.post('https://manage.onenike.com/api/addmodule.php', newModule.value).then(() => {
        fetchModules();
        newModule.value = { name: '', description: '' };
        ElMessage.success('模块添加成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('添加模块失败');
      });
    };

    const editModule = (module) => {
      editedModule.value = { ...module };
      editMode.value = true;
    };

    const updateModule = () => {
      axios.post('https://manage.onenike.com/api/editmodule.php', editedModule.value).then(() => {
        fetchModules();
        editMode.value = false;
        ElMessage.success('模块更新成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('更新模块失败');
      });
    };

    const deleteModule = (module) => {
      axios.post('https://manage.onenike.com/api/deletemodule.php', { id: module.id }).then(() => {
        fetchModules();
        ElMessage.success('模块删除成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('删除模块失败');
      });
    };

    const cancelEdit = () => {
      editMode.value = false;
      editedModule.value = {};
    };

    const fetchSkips = () => {
      axios.get('https://manage.onenike.com/api/getskips.php').then(response => {
        skips.value = response.data;
      }).catch(error => {
        console.error(error);
        ElMessage.error('获取CMS类型信息失败');
      });
    };

    const addSkip = () => {
      if (!newSkip.value.name) {
        ElMessage.warning('CMS类型名不能为空');
        return;
      }
      axios.post('https://manage.onenike.com/api/addskip.php', newSkip.value).then(() => {
        fetchSkips();
        newSkip.value = { name: '' };
        ElMessage.success('CMS类型添加成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('添加CMS类型失败');
      });
    };

    const editSkip = (skip) => {
      editedSkip.value = { ...skip };
      editSkipMode.value = true;
    };

    const updateSkip = () => {
      axios.post('https://manage.onenike.com/api/editskip.php', editedSkip.value).then(() => {
        fetchSkips();
        editSkipMode.value = false;
        ElMessage.success('CMS类型更新成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('更新CMS类型失败');
      });
    };

    const deleteSkip = (skip) => {
      axios.post('https://manage.onenike.com/api/deleteskip.php', { id: skip.id }).then(() => {
        fetchSkips();
        ElMessage.success('CMS类型删除成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('删除CMS类型失败');
      });
    };

    const cancelSkipEdit = () => {
      editSkipMode.value = false;
      editedSkip.value = {};
    };

    // AWVS API 设置管理逻辑
    const awvsApis = ref([]);
    const newAwvsApi = ref({
      awvsUrl: '',
      api: ''
    });
    const editedAwvsApi = ref({});
    const editAwvsApiMode = ref(false);

    const fetchAwvsApis = () => {
      axios.get('https://manage.onenike.com/api/getAwvsApiSettings.php').then(response => {
        awvsApis.value = response.data;
      }).catch(error => {
        console.error(error);
        ElMessage.error('获取 AWVS API 设置失败');
      });
    };

    const addAwvsApi = () => {
      if (!newAwvsApi.value.awvsUrl || !newAwvsApi.value.api) {
        ElMessage.warning('AWVS URL 和 API 不能为空');
        return;
      }
      axios.post('https://manage.onenike.com/api/addAwvsApiSettings.php', newAwvsApi.value).then(() => {
        fetchAwvsApis();
        newAwvsApi.value = { awvsUrl: '', api: '' };
        ElMessage.success('AWVS API 设置添加成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('添加 AWVS API 设置失败');
      });
    };

    const editAwvsApi = (awvsApi) => {
      editedAwvsApi.value = { ...awvsApi };
      editAwvsApiMode.value = true;
    };

    const updateAwvsApi = () => {
      axios.post('https://manage.onenike.com/api/editAwvsApiSettings.php', editedAwvsApi.value).then(() => {
        fetchAwvsApis();
        editAwvsApiMode.value = false;
        ElMessage.success('AWVS API 设置更新成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('更新 AWVS API 设置失败');
      });
    };

    const deleteAwvsApi = (awvsApi) => {
      axios.post('https://manage.onenike.com/api/deleteAwvsApiSettings.php', { id: awvsApi.id }).then(() => {
        fetchAwvsApis();
        ElMessage.success('AWVS API 设置删除成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('删除 AWVS API 设置失败');
      });
    };

    const cancelAwvsApiEdit = () => {
      editAwvsApiMode.value = false;
      editedAwvsApi.value = {};
    };

    // 初始化时获取所有数据
    fetchModules();
    fetchSkips();
    fetchAwvsApis();

    return {
      // 模块管理的返回
      modules,
      newModule,
      editedModule,
      editMode,
      addModule,
      editModule,
      updateModule,
      deleteModule,
      cancelEdit,

      // CMS类型管理的返回
      skips,
      newSkip,
      editedSkip,
      editSkipMode,
      addSkip,
      editSkip,
      updateSkip,
      deleteSkip,
      cancelSkipEdit,

      // AWVS API 设置管理的返回
      awvsApis,
      newAwvsApi,
      editedAwvsApi,
      editAwvsApiMode,
      addAwvsApi,
      editAwvsApi,
      updateAwvsApi,
      deleteAwvsApi,
      cancelAwvsApiEdit
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5; /* 白色背景 */
  color: #333; /* 深色文本 */
}

.modules-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.box-card {
  width: 80%;
}

.form-container {
  margin-bottom: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-container {
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.skip-management,
.awvs-api-management {
  margin-top: 40px;
}

.el-form-item__label {
  width: 100px;
}
</style>
