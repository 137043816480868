<template>
    <div class="code-block">
      <pre class="line-numbers">
        <code ref="code" :class="'language-' + language">{{ code }}</code>
      </pre>
      <el-button class="copy-btn" :data-clipboard-text="code" @click="copyCode">复制代码</el-button>
    </div>
  </template>
  
  <script>
  import Prism from 'prismjs';
  import Clipboard from 'clipboard';
  import { ElMessage } from 'element-plus';
  
  export default {
    name: 'CodeBlock',
    props: {
      code: {
        type: String,
        required: true
      },
      language: {
        type: String,
        default: 'bash'
      }
    },
    mounted() {
      Prism.highlightElement(this.$refs.code);
      this.clipboard = new Clipboard('.copy-btn');
      this.clipboard.on('success', () => {
        ElMessage({
          message: '复制成功！',
          type: 'success'
        });
      });
      this.clipboard.on('error', () => {
        ElMessage({
          message: '复制失败！',
          type: 'error'
        });
      });
    },
    beforeUnmount() {
      this.clipboard.destroy();
    },
    methods: {
      copyCode() {
        // 复制代码的按钮动作已经由 Clipboard.js 处理，这里不需要额外的逻辑
      }
    }
  };
  </script>
  
  <style scoped>
  .code-block {
    position: relative;
    margin-bottom: 20px;
    background-color: #2d2d2d;
    border-radius: 5px;
    padding: 15px;
    color: #ffffff;
    font-family: 'Roboto Mono', monospace;
  }
  
  .copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #42b983;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .copy-btn:hover {
    background-color: #2d9c72;
  }
  
  pre {
    margin: 0;
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;
  }
  
  code {
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;
    color: inherit;
  }
  </style>
  