<template>
  <div class="home-container">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <h2>欢迎来到系统管理</h2>
          <p>请选择左侧菜单进入相应的管理页面。</p>
          <el-divider></el-divider>
          <div v-if="dataLoaded">
            <el-button type="danger" @click="resetRunning" class="reset-button">一键重置running</el-button>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-card shadow="hover">
                  <h3>URL状态数量</h3>
                  <el-table :data="statusData" style="width: 100%">
                    <el-table-column prop="status" label="状态"></el-table-column>
                    <el-table-column prop="count" label="数量"></el-table-column>
                  </el-table>
                </el-card>
                <el-card shadow="hover">
                  <div id="bugcard" style="width: 100%;height: 400px; margin-top: 20px;"></div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card shadow="hover">
                  <h3>漏洞类型数量</h3>
                  <el-table :data="vulnerabilityData" style="width: 100%">
                    <el-table-column prop="type" label="漏洞类型">
                      <template #default="scope">
                        <router-link :to="{ name: 'VulnerabilityDetails', params: { type: scope.row.type } }">
                          <el-button type="primary" plain>{{ scope.row.type }}</el-button>
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量"></el-table-column>
                  </el-table>
                </el-card>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <div>
              <h3>批量修改任务模块</h3>
              <el-checkbox-group v-model="selectedModules">
                <el-checkbox v-for="module in modules" :key="module.id" :label="module.name">{{ module.name }}</el-checkbox>
              </el-checkbox-group>
              <el-button type="primary" @click="updateModules">一键修改</el-button>
            </div>
          </div>
          <div v-else>
            <el-loading-text>正在加载数据...</el-loading-text>
          </div>
          <el-divider></el-divider>
          <CodeBlock code="curl -O https://manage.onenike.com/sh.sh && chmod +x sh.sh && ./sh.sh" language="bash" />
          <CodeBlock code="dotnet LinuxShell.dll" language="bash" />
        </el-card>
      </el-col>

      <el-col :span="24">
        <el-card class="box-card">
          <el-collapse>
            <el-collapse-item title="服务端手动安装说明文档">
              <p>
                <!-- 文档内容保持不变 -->
              </p>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus';
import { ref, onMounted, nextTick } from 'vue';
import CodeBlock from '@/components/CodeBlock.vue'; // 确保路径正确
import * as echarts from 'echarts';

export default {
  name: 'HomeView',
  components: {
    CodeBlock
  },
  setup() {
    const statusCounts = ref({});
    const vulnerabilityCounts = ref({});
    const dataLoaded = ref(false);
    const statusData = ref([]);
    const vulnerabilityData = ref([]);
    const modules = ref([]);
    const selectedModules = ref([]);

    const fetchData = async () => {
      const loading = ElLoading.service({ fullscreen: true });
      try {
        const response = await fetch('https://manage.onenike.com/api/get_homepage_data.php');
        const data = await response.json();
        statusCounts.value = data.statusCounts;
        vulnerabilityCounts.value = data.vulnerabilityCounts;
        statusData.value = Object.keys(statusCounts.value).map(key => ({
          status: key,
          count: statusCounts.value[key]
        }));
        vulnerabilityData.value = Object.keys(vulnerabilityCounts.value).map(key => ({
          type: key,
          count: vulnerabilityCounts.value[key]
        }));
        const modulesResponse = await fetch('https://manage.onenike.com/api/getmodule.php');
        const modulesData = await modulesResponse.json();
        modules.value = modulesData;
        dataLoaded.value = true;
        await nextTick(); // 等待 DOM 渲染完成
        updateChart();
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        loading.close();
      }
    };

    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const updateChart = () => {
      var myChart = echarts.init(document.getElementById('bugcard'));
      myChart.setOption({
        title: {
          text: '漏洞统计'
        },
        tooltip: {},
        legend: {
          data: ['漏洞']
        },
        xAxis: {
          type: 'category',
          data: vulnerabilityData.value.map(item => item.type)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '漏洞',
            type: 'bar',
            data: vulnerabilityData.value.map(item => item.count),
            itemStyle: {
              color: function (params) { // eslint-disable-line no-unused-vars
                return getRandomColor();
              }
            }
          }
        ]
      });
    };

    const resetRunning = async () => {
      const loading = ElLoading.service({ fullscreen: true });
      try {
        const response = await fetch('https://manage.onenike.com/api/reset.php', {
          method: 'POST',
        });
        const data = await response.json();
        if (data.success) {
          ElMessage.success('重置成功');
        } else {
          ElMessage.error('重置失败');
        }
      } catch (error) {
        console.error('Error resetting running:', error);
        ElMessage.error('重置过程中发生错误');
      } finally {
        loading.close();
      }
    };

    const updateModules = async () => {
      const loading = ElLoading.service({ fullscreen: true });
      try {
        const response = await fetch('https://manage.onenike.com/api/update_modules.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ modules: selectedModules.value.join(',') })
        });
        const data = await response.json();
        if (data.success) {
          ElMessage.success('模块更新成功');
        } else {
          ElMessage.error('模块更新失败');
        }
      } catch (error) {
        console.error('Error updating modules:', error);
        ElMessage.error('更新模块过程中发生错误');
      } finally {
        loading.close();
      }
    };

    onMounted(() => {
      fetchData();
    });

    return {
      statusCounts,
      vulnerabilityCounts,
      dataLoaded,
      statusData,
      vulnerabilityData,
      modules,
      selectedModules,
      resetRunning,
      updateModules
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
}

.home-container {
  padding: 20px;
}

.el-card {
  background: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.el-table {
  width: 100%;
}

.el-divider {
  margin: 20px 0;
}

.el-button {
  margin: 5px 0;
  font-weight: bold;
}

.reset-button {
  margin-bottom: 20px;
}

.documentation {
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
}
</style>
