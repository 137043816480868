<template>
  <div class="url-manage-container">
    <el-card class="box-card">
      <template #header>
        <div class="header">
          <el-icon><el-icon-link /></el-icon>
          <span>URL 管理</span>
        </div>
      </template>
      <div class="content">
        <el-alert v-if="allLoading" title="所有请求正在加载中，请稍候..." type="info" show-icon></el-alert>

        <el-input v-model="searchQuery" placeholder="搜索 URL" @input="fetchUrls" class="search-input"></el-input>

        <el-card class="box-card">
          <template #header>
            <div class="clearfix">
              <span>添加 URL</span>
            </div>
          </template>
          <el-form :model="newURL" label-width="80px" class="form-container">
            <el-form-item label="批量 URL">
              <el-input type="textarea" v-model="newURL.urls" placeholder="每行一个 URL"></el-input>
            </el-form-item>
            <el-form-item label="启用的模块">
              <el-checkbox-group v-model="newURL.modules_enable">
                <el-checkbox v-for="module in modules" :label="module.name" :key="module.id">{{ module.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="newURL.status" placeholder="选择状态">
                <el-option label="pending" value="pending"></el-option>
                <el-option label="running" value="running"></el-option>
                <el-option label="completed" value="completed"></el-option>
                <el-option label="failed" value="failed"></el-option>
                <el-option label="bugs" value="bugs"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="addURLs">批量添加</el-button>
            </el-form-item>
          </el-form>
        </el-card>

        <!-- 显示成功添加的 URL -->
        <el-card class="box-card" v-if="successUrls.length > 0">
          <template #header>
            <div class="clearfix">
              <span>成功添加的 URL</span>
            </div>
          </template>
          <div class="success-urls">
            <ul>
              <li v-for="url in successUrls" :key="url">{{ url }}</li>
            </ul>
          </div>
        </el-card>

        <!-- 显示添加失败的 URL -->
        <el-card class="box-card" v-if="failedUrls.length > 0" type="danger">
          <template #header>
            <div class="clearfix">
              <span>添加失败的 URL</span>
            </div>
          </template>
          <div class="failed-urls">
            <ul>
              <li v-for="error in failedUrls" :key="error.url">
                <strong>{{ error.url }}</strong>: {{ error.message }}
              </li>
            </ul>
          </div>
        </el-card>

        <el-tabs v-model="activeTab" @tab-click="handleTabClick">
          <el-tab-pane :label="'待处理(' + statusCounts.pending + ')' " name="pending"></el-tab-pane>
          <el-tab-pane :label="'运行中(' + statusCounts.running + ')' " name="running"></el-tab-pane>
          <el-tab-pane :label="'已完成(' + statusCounts.completed + ')' " name="completed"></el-tab-pane>
          <el-tab-pane :label="'已审查(' + statusCounts.failed + ')' " name="failed"></el-tab-pane>
          <el-tab-pane :label="'有漏洞(' + statusCounts.bugs + ')' " name="bugs"></el-tab-pane>
        </el-tabs>

        <el-card class="box-card bulk-action-container">
          <template #header>
            <div class="clearfix">
              <span>批量操作</span>
            </div>
          </template>
          <el-form label-width="120px">
            <el-form-item label="批量修改的模块">
              <el-select v-model="bulkModules" placeholder="选择批量修改的模块" multiple>
                <el-option v-for="module in modules" :label="module.name" :value="module.name" :key="module.id">{{ module.name }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="批量修改的状态">
              <el-select v-model="bulkStatus" placeholder="选择批量修改的状态">
                <el-option label="pending" value="pending"></el-option>
                <el-option label="running" value="running"></el-option>
                <el-option label="completed" value="completed"></el-option>
                <el-option label="failed" value="failed"></el-option>
                <el-option label="bugs" value="bugs"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="updateSelectedURLs" :disabled="selectedURLs.length === 0">批量修改</el-button>
            </el-form-item>
          </el-form>
          <el-button type="danger" @click="deleteSelectedURLs" :disabled="selectedURLs.length === 0">批量已查</el-button>
        </el-card>

        <el-card class="box-card risk-level-select">
          <template #header>
            <div class="clearfix">
              <span>根据危害等级选择</span>
            </div>
          </template>
          <el-checkbox v-model="riskLevelFilter.unknown">未知</el-checkbox>
          <el-checkbox v-model="riskLevelFilter.high">高危</el-checkbox>
          <el-checkbox v-model="riskLevelFilter.medium">中危</el-checkbox>
          <el-checkbox v-model="riskLevelFilter.low">低危</el-checkbox>
        </el-card>

        <el-button type="primary" @click="bulkDownload" :disabled="selectedURLs.length === 0">按危害等级批量下载</el-button>
        <el-button type="primary" @click="toggleExpandAll">{{ expandAll ? '收缩所有' : '展开所有' }}</el-button>

        <div class="table-container">
          <h2>URL 列表</h2>
          <el-table :data="urls" style="width: 100%;" @selection-change="handleSelectionChange" row-key="id" :expand-row-keys="expandAll ? expandedRowKeys : []">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="url" label="URL"></el-table-column>
            <el-table-column prop="modules_enable" label="启用的模块">
              <template #default="scope">
                <span v-for="(module, index) in scope.row.modules_enable.split(',')" :key="index">
                  <el-tag>{{ module }}</el-tag>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="created_at" label="创建时间"></el-table-column>
            <el-table-column prop="started_at" label="开始时间"></el-table-column>
            <el-table-column prop="completed_at" label="完成时间"></el-table-column>
            <el-table-column prop="scan_machine_ip" label="扫描机器IP"></el-table-column>
            <el-table-column prop="traffic" label="日均流量">
              <template #default="scope">
                <span v-if="scope.row.traffic !== null">{{ scope.row.traffic }}</span>
                <el-button v-else type="text" @click="checkTraffic(scope.row)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column type="expand">
              <template #default="scope">
                <el-row>
                  <el-col :span="24">
                    <div v-if="scope.row.hasResults">
                      <span v-for="result in scope.row.results" :key="result.id" :style="getButtonStyle(result.vulnerability_type)">
                        <el-popover placement="top" width="300" trigger="hover">
                          <template #reference>
                            <el-button :type="randomType()" plain>{{ formatButtonLabel(result.vulnerability_type, result.result) }}</el-button>
                          </template>
                          <div class="result-content" v-if="result.module && result.module === 'goon'">
                            <p v-html="formatGoonContent(result.result)"></p>
                          </div>
                          <div class="result-content" v-else-if="result.module && result.module === 'gogo' && result.result">
                            <pre>{{ formatJsonContent(result.result) }}</pre>
                          </div>
                          <div class="result-content" v-else-if="result.module && result.module === 'awvs'">
                            <span v-if="result.resultDescription" class="result-description">{{ result.resultDescription }}</span>
                            <el-button type="primary" @click="viewAwvsJson(result.result)">查看 JSON</el-button>
                          </div>
                          <div class="result-content" v-else-if="isJson(result.result)">
                            <div v-for="(item, index) in parseJson(result.result)" :key="index">
                              <p>{{ item }}</p>
                            </div>
                          </div>
                          <div class="result-content" v-else>
                            <div v-if="isDownloadLink(result.result) === 'html'">
                              <el-button type="primary" @click="openLink(result.result)">打开 HTML</el-button>
                            </div>
                            <div v-else-if="isDownloadLink(result.result) === 'json'">
                              <el-button type="primary" @click="viewJson(result.result)">查看 JSON</el-button>
                            </div>
                            <div v-else>
                              {{ result.result }}
                            </div>
                          </div>
                        </el-popover>
                        <el-tag
  v-if="result.risk_level === 'unknown'"
  type="danger"
  class="risk-tag risk-unknown"
>未知</el-tag>
<el-tag
  v-else-if="result.risk_level === 'high'"
  class="risk-tag risk-high"
>高危</el-tag>
<el-tag
  v-else-if="result.risk_level === 'medium'"
  class="risk-tag risk-medium"
>中危</el-tag>
<el-tag
  v-else-if="result.risk_level === 'low'"
  class="risk-tag risk-low"
>低危</el-tag>

                      </span>
                    </div>
                    <div v-else>
                      还没扫出漏洞
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="查看漏洞">
              <template #default="scope">
                <div v-if="scope.row.loadingResults">
                  加载中，请稍候...
                </div>
                <div v-else>
                  <div v-if="scope.row.hasResults">
                    <el-button type="text" size="small" @click="viewScanResults(scope.row.id)">打探出情报了！</el-button>
                  </div>
                  <div v-else>
                    还没扫出漏洞
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button-group>
                  <el-button type="primary" size="small" @click="editURL(scope.row)">编辑</el-button>
                  <el-button type="warning" size="small" @click="deleteURL(scope.row)">设为已查</el-button>
                  <el-button type="danger" size="small" @click="bugsURL(scope.row)">设为有漏洞</el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              @size-change="handleUrlsSizeChange"
              @current-change="fetchUrls"
              :current-page="urlsPage"
              :page-size="urlsPerPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="urlsTotal">
            </el-pagination>
          </div>
        </div>

        <el-drawer v-model="editMode" direction="rtl" size="30%">
          <template #header>
            <div class="header">
              <el-icon><el-icon-edit /></el-icon>
              <span>编辑 URL</span>
            </div>
          </template>
          <el-form :model="editedURL" label-width="80px">
            <el-form-item label="URL">
              <el-input v-model="editedURL.url"></el-input>
            </el-form-item>
            <el-form-item label="启用的模块">
              <el-checkbox-group v-model="editedURL.modules_enable">
                <el-checkbox v-for="module in modules" :label="module.name" :key="module.id">{{ module.name }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="editedURL.status" placeholder="选择状态">
                <el-option label="pending" value="pending"></el-option>
                <el-option label="running" value="running"></el-option>
                <el-option label="completed" value="completed"></el-option>
                <el-option label="failed" value="failed"></el-option>
                <el-option label="bugs" value="bugs"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="updateURL">保存</el-button>
              <el-button @click="cancelEdit">取消</el-button>
            </el-form-item>
          </el-form>
        </el-drawer>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, nextTick, watch, onMounted, onBeforeUnmount } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';

export default {
  name: 'URLManageView',
  setup() {
    const router = useRouter();
    const urls = ref([]);
    const modules = ref([]);
    const newURL = ref({
      urls: '',
      modules_enable: [],
      status: 'pending'
    });
    const successUrls = ref([]);
    const failedUrls = ref([]);
    const editedURL = ref({});
    const editMode = ref(false);
    const selectedURLs = ref([]);
    const bulkModules = ref([]);
    const bulkStatus = ref('');
    const urlsTotal = ref(0);
    const urlsPage = ref(1);
    const urlsPerPage = ref(10);
    const expandedRowKeys = ref([]);
    const expandAll = ref(false);
    const editForm = ref(null);
    const riskLevelFilter = ref({
      unknown: false,
      high: false,
      medium: false,
      low: false,
    });
    const activeTab = ref('pending');
    const statusCounts = ref({ pending: 0, running: 0, completed: 0, failed: 0, bugs: 0 });
    const searchQuery = ref('');
    const allLoading = ref(false);
    const observer = ref(null);
    const loadMoreTrigger = ref(null);

    let cancelTokenSource = null;

    const fetchUrls = async (page = 1, perPage = 10) => {
      if (allLoading.value) return;
      allLoading.value = true;

      if (cancelTokenSource) {
        cancelTokenSource.cancel('请求被取消');
      }

      cancelTokenSource = axios.CancelToken.source();

      try {
        const response = await axios.get(`https://manage.onenike.com/api/get_urls.php`, {
          params: {
            status: activeTab.value,
            page,
            per_page: perPage,
            search: searchQuery.value
          },
          cancelToken: cancelTokenSource.token
        });
        const data = response.data.data;
        urlsTotal.value = response.data.total;
        urlsPage.value = response.data.page;
        urlsPerPage.value = response.data.per_page;

        urls.value = data.map(url => ({
          ...url,
          loadingResults: true,
          results: [],
          traffic: url.traffic !== null ? url.traffic : null,
          hasResults: false
        }));

        for (let url of urls.value) {
          const vulnerability_types = await getVulnerabilityTypes(url.id);
          const results_with_vulnerability = await getResultsWithVulnerability(url.id, vulnerability_types);
          const traffic = url.traffic !== null ? url.traffic : await fetchTraffic(url);

          url.hasResults = results_with_vulnerability.length > 0;
          url.results = results_with_vulnerability.map(result => ({
            ...result,
            resultDescription: extractDescription(result.result)
          }));
          url.traffic = traffic;
          url.loadingResults = false;
        }

        statusCounts.value = {
          pending: response.data.status_counts.pending || 0,
          running: response.data.status_counts.running || 0,
          completed: response.data.status_counts.completed || 0,
          failed: response.data.status_counts.failed || 0,
          bugs: response.data.status_counts.bugs || 0,
        };

        expandedRowKeys.value = urls.value.map(url => url.id);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('请求被取消', error.message);
        } else {
          console.error(error);
          ElMessage.error('获取 URL 信息失败');
        }
      } finally {
        allLoading.value = false;
      }
    };

    const fetchModules = async () => {
      try {
        const response = await axios.get('https://manage.onenike.com/api/getmodules.php');
        modules.value = response.data;
      } catch (error) {
        console.error('获取模块信息失败:', error);
      }
    };

    const getVulnerabilityTypes = async (scan_id) => {
      try {
        const response = await axios.get(`https://manage.onenike.com/api/get_vulnerability_types.php?scan_id=${scan_id}`);
        return response.data.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    const getResultsWithVulnerability = async (scan_id, vulnerability_types) => {
      try {
        const results = await Promise.all(
          vulnerability_types.map(async (vulnerability_type) => {
            const response = await axios.get(`https://manage.onenike.com/api/get_vulnerability_results.php?scan_id=${scan_id}&vulnerability_type=${encodeURIComponent(vulnerability_type)}`);
            return response.data.data.map(result => ({
              ...result,
              vulnerability_type
            }));
          })
        );
        return results.flat();
      } catch (error) {
        console.error(error);
        return [];
      }
    };

    const fetchTraffic = async (url) => {
      try {
        const domain = new URL(url.url).hostname;
        const hexCode = Array.from(domain).map(char => char.charCodeAt(0).toString(16)).join('');
        const response = await axios.get(`https://manage.onenike.com/api/fetch.php?hexCode=${hexCode}`);

        const data = response.data;
        if (data.av) {
          await updateTraffic(url.id, data.av);
          return data.av;
        } else {
          return null;
        }
      } catch (error) {
        console.error('获取日均流量失败:', error);
        return null;
      }
    };

    const updateTraffic = async (id, traffic) => {
      try {
        await axios.post('https://manage.onenike.com/api/update_traffic.php', { id, traffic });
      } catch (error) {
        console.error('更新日均流量失败:', error);
      }
    };

    const extractDescription = (result) => {
      const jsonStartIndex = result.indexOf('{');
      return jsonStartIndex !== -1 ? result.substring(0, jsonStartIndex).trim() : result.trim();
    };

    const addURLs = async () => {
  allLoading.value = true;
  const urlList = newURL.value.urls.split('\n').map(url => url.trim()).filter(url => url);

  successUrls.value = [];
  failedUrls.value = [];

  try {
    const chunkSize = 10; // 每组处理10个URL
    for (let i = 0; i < urlList.length; i += chunkSize) {
      const urlChunk = urlList.slice(i, i + chunkSize);

      const requests = urlChunk.map(url =>
        axios.post('https://manage.onenike.com/api/addurl.php', {
          url,
          modules_enable: newURL.value.modules_enable.join(','),
          status: newURL.value.status
        }).then(response => {
          if (response.data.status === 'error') {
            throw new Error(response.data.message);
          }
          successUrls.value.push(url);
          return { success: true, response };
        }).catch(error => {
          failedUrls.value.push({ url, message: error.message });
          return { success: false, error };
        })
      );

      await Promise.all(requests); // 等待该组的所有请求完成
    }

    if (failedUrls.value.length > 0) {
      ElMessage.error(`部分 URL 添加失败`);
    } else {
      ElMessage.success('所有 URL 添加成功');
    }

    await fetchUrls();
    newURL.value = { urls: '', modules_enable: [], status: 'pending' };
  } catch (error) {
    console.error(error);
    ElMessage.error(`批量添加 URL 失败: ${error.message}`);
  } finally {
    allLoading.value = false;
  }
};
    const editURL = (url) => {
      editedURL.value = { ...url, modules_enable: url.modules_enable.split(',') };
      editMode.value = true;
      nextTick(() => {
        editForm.value.scrollIntoView({ behavior: 'smooth' });
      });
    };

    const updateURL = () => {
      allLoading.value = true;
      axios.post('https://manage.onenike.com/api/editurl.php', {
        ...editedURL.value,
        modules_enable: editedURL.value.modules_enable.join(',')
      }).then(() => {
        fetchUrls();
        editMode.value = false;
        ElMessage.success('URL 更新成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('更新 URL 失败');
      }).finally(() => {
        allLoading.value = false;
      });
    };

    const deleteURL = async (url) => {
      allLoading.value = true;
      axios.post('https://manage.onenike.com/api/deleteurl.php', { id: url.id }).then(() => {
        fetchUrls();
        urls.value = urls.value.filter(item => item.id !== url.id);
        ElMessage.success('URL设置已查成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('URL设置已查失败');
      }).finally(() => {
        allLoading.value = false;
      });
    };

    const bugsURL = async (url) => {
      allLoading.value = true;
      axios.post('https://manage.onenike.com/api/bugsurl.php', { id: url.id }).then(() => {
        fetchUrls();
        urls.value = urls.value.filter(item => item.id !== url.id);
        ElMessage.success('URL设置有漏洞成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('URL设置有漏洞失败');
      }).finally(() => {
        allLoading.value = false;
      });
    };

    const deleteSelectedURLs = () => {
      allLoading.value = true;
      const requests = selectedURLs.value.map(url => {
        return axios.post('https://manage.onenike.com/api/deleteurl.php', { id: url.id });
      });

      Promise.all(requests).then(() => {
        fetchUrls();
        selectedURLs.value.forEach(deletedUrl => {
          urls.value = urls.value.filter(item => item.id !== deletedUrl.id);
        });
        ElMessage.success('URL 批量删除成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('批量删除 URL 失败');
      }).finally(() => {
        allLoading.value = false;
      });
    };

    const updateSelectedURLs = () => {
      allLoading.value = true;
      const requests = selectedURLs.value.map(url => {
        return axios.post('https://manage.onenike.com/api/editurl.php', {
          id: url.id,
          modules_enable: bulkModules.value.join(','),
          status: bulkStatus.value || url.status
        });
      });

      Promise.all(requests).then(() => {
        fetchUrls();
        ElMessage.success('URL 批量修改成功');
      }).catch(error => {
        console.error(error);
        ElMessage.error('批量修改 URL 失败');
      }).finally(() => {
        allLoading.value = false;
      });
    };

    const cancelEdit = () => {
      editMode.value = false;
      editedURL.value = {};
    };

    const handleUrlsSizeChange = (size) => {
      urlsPerPage.value = size;
      fetchUrls(urlsPage.value, urlsPerPage.value);
    };

    const viewScanResults = (scanId) => {
      router.push(`/scanresults/${scanId}`);
    };

    const isJson = (result) => {
      try {
        JSON.parse(result);
        return true;
      } catch (e) {
        return false;
      }
    };

    const parseJson = (result) => {
      return JSON.parse(result);
    };

    const downloadFile = (url) => {
      window.open(url, '_blank');
    };

    const formatGoonContent = (content) => {
      const lines = content.split('------------------------------------');
      return lines.map(line => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return '';
        if (trimmedLine.startsWith('port')) return `<strong>端口</strong>: ${trimmedLine.replace('port', '').trim()}`;
        if (trimmedLine.startsWith('url')) return `<strong>URL</strong>: ${trimmedLine.replace('url', '').trim()}`;
        if (trimmedLine.startsWith('title')) return `<strong>标题</strong>: ${trimmedLine.replace('title', '').trim()}`;
        if (trimmedLine.startsWith('finger')) return `<strong>指纹</strong>: ${trimmedLine.replace('finger', '').trim()}`;
        if (trimmedLine.startsWith('ftp')) return `<strong>FTP</strong>: ${trimmedLine.replace('ftp', '').trim()}`;
        if (trimmedLine.startsWith('ssh')) return `<strong>SSH</strong>: ${trimmedLine.replace('ssh', '').trim()}`;
        return trimmedLine;
      }).join('<br/>');
    };

    const formatJsonContent = (jsonString) => {
      const cleanedJsonString = cleanJsonString(jsonString);
      const json = JSON.parse(cleanedJsonString);
      return JSON.stringify(json, null, 2);
    };

    const cleanJsonString = (jsonString) => {
      return jsonString.replace(/\\(?!["\\bfnrtu])/g, '\\\\');
    };

    const getButtonStyle = (vulnerabilityType) => {
      const colors = {
        xray: 'background-color: #409eff; color: white; margin: 2px;',
        bbscan: 'background-color: #f56c6c; color: white; margin: 2px;',
        ksubdomain: 'background-color: #67c23a; color: white; margin: 2px;',
        default: 'background-color: #909399; color: white; margin: 2px;'
      };
      return colors[vulnerabilityType] || colors.default;
    };

    const randomType = () => {
      const types = ['primary', 'success', 'warning', 'info', 'danger'];
      return types[Math.floor(Math.random() * types.length)];
    };

    const bulkDownload = () => {
      try {
        selectedURLs.value.forEach(url => {
          url.results.forEach(result => {
            if (riskLevelFilter.value[result.risk_level] && isDownloadLink(result.result)) {
              window.open(result.result, '_blank');
            }
          });
        });
      } finally {
        allLoading.value = false;
      }
    };

    const isDownloadLink = (result) => {
      if (result.startsWith('https://manage.onenike.com/api/uploaded_files/')) {
        return result.endsWith('.json') ? 'json' : 'html';
      }
      return false;
    };

    const formatButtonLabel = (vulnerabilityType, resultUrl) => {
      try {
        const specificModules = ['xray', 'wscan', 'bbscan'];
        if (specificModules.includes(vulnerabilityType)) {
          const urlParts = resultUrl.split('/');
          const fileName = urlParts[urlParts.length - 1];
          const domainParts = fileName.split('-');
          const domain = domainParts.slice(1, domainParts.length - 1).join('-');
          return `${vulnerabilityType}-${domain}`;
        } else {
          return vulnerabilityType;
        }
      } catch (error) {
        console.error('Error extracting domain:', resultUrl, error);
        return vulnerabilityType;
      }
    };

    const checkTraffic = async (url) => {
      try {
        const traffic = await fetchTraffic(url);
        if (traffic) {
          url.traffic = traffic;
          fetchUrls(urlsPage.value, urlsPerPage.value);
        } else {
          const domain = new URL(url.url).hostname;
          const hexCode = Array.from(domain).map(char => char.charCodeAt(0).toString(16)).join('');
          const trafficUrl = `https://plugin.myip.ms/hex_${hexCode}&lang=zh_CN`;
          window.open(trafficUrl, '_blank');
        }
      } catch (error) {
        console.error('获取日均流量失败:', error);
      }
    };

    const toggleExpandAll = () => {
      expandAll.value = !expandAll.value;
    };

    const loadMore = () => {
      if (urlsTotal.value > urls.value.length) {
        fetchUrls(urlsPage.value + 1, urlsPerPage.value);
      }
    };

    const createObserver = () => {
      observer.value = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !allLoading.value) {
          loadMore();
        }
      });
      if (loadMoreTrigger.value) {
        observer.value.observe(loadMoreTrigger.value);
      }
    };

    const destroyObserver = () => {
      if (observer.value && loadMoreTrigger.value) {
        observer.value.unobserve(loadMoreTrigger.value);
        observer.value.disconnect();
      }
    };

    const openLink = (url) => {
      window.open(url, '_blank');
    };

    const viewJson = (url) => {
      window.open(`/json-viewer?url=${encodeURIComponent(url)}`, '_blank');
    };

    const handleTabClick = (tab) => {
      activeTab.value = tab.props.name;
      urls.value = [];
      urlsPage.value = 1;
      urlsTotal.value = 0;
      expandedRowKeys.value = [];
      fetchUrls(1, urlsPerPage.value);
    };
 
    const viewAwvsJson = async (url) => {
      if (typeof url !== 'string') {
        console.error('url is not a string');
        return;
      }
      const cleanedResult = cleanJsonString(url);

      const jsonStartIndex = cleanedResult.indexOf('{');
      const descriptionText = cleanedResult.substring(0, jsonStartIndex).trim();
      const jsonString = cleanedResult.substring(jsonStartIndex).trim();

      const dataToSend = {
        description: descriptionText,
        json: JSON.parse(jsonString)
      };

      try {
        const response = await axios.post('https://manage.onenike.com/api/save_awvs_data.php', dataToSend, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const { id } = response.data;
        if (id) {
          window.open(`/awvs-viewer?id=${id}&description=${encodeURIComponent(descriptionText)}`, '_blank');
        } else {
          throw new Error('Failed to save JSON data');
        }
      } catch (error) {
        console.error('Error saving JSON data:', error);
      }
    };

    watch(activeTab, () => {
      fetchUrls(1, urlsPerPage.value);
    });

    onMounted(() => {
      createObserver();
      fetchModules();
      fetchUrls(1, urlsPerPage.value);
    });

    onBeforeUnmount(() => {
      destroyObserver();
    });

    return {
      urls,
      successUrls,
      failedUrls,
      modules,
      newURL,
      editedURL,
      editMode,
      selectedURLs,
      bulkModules,
      bulkStatus,
      urlsTotal,
      urlsPage,
      urlsPerPage,
      expandedRowKeys,
      expandAll,
      activeTab,
      statusCounts,
      searchQuery,
      fetchUrls,
      viewAwvsJson,
      fetchModules,
      addURLs,
      editURL,
      updateURL,
      deleteURL,
      bugsURL,
      deleteSelectedURLs,
      updateSelectedURLs,
      cancelEdit,
      handleUrlsSizeChange,
      viewScanResults,
      isJson,
      parseJson,
      downloadFile,
      formatGoonContent,
      formatJsonContent,
      getButtonStyle,
      randomType,
      bulkDownload,
      isDownloadLink,
      editForm,
      riskLevelFilter,
      handleSelectionChange(val) {
        selectedURLs.value = val;
      },
      formatButtonLabel,
      checkTraffic,
      toggleExpandAll,
      handleTabClick,
      allLoading,
      loadMoreTrigger,
      openLink,
      viewJson
    };
  }
};
</script>

<style scoped>
.url-manage-container {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #0e0e0e;
  color: #0f0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.search-input {
  margin-bottom: 20px;
}

.global-loading {
  text-align: center;
  font-size: 18px;
  color: #ff9800;
  margin-bottom: 20px;
}

.content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  color: #333;
}

.form-container {
  margin-bottom: 20px;
}

.bulk-action-container {
  margin-bottom: 20px;
}

.risk-level-select {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-container {
  margin-top: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: center;
}

.el-input__inner,
.el-select__inner {
  background-color: #e0e0e0;
  color: #333;
  border: 1px solid #0e0e0e;
}

.el-button--primary {
  background-color: #0f0;
  border: none;
  color: #0e0e0e;
}

.el-button--primary:hover {
  background-color: #0c0;
}

.el-table {
  background-color: #f5f5f5;
  color: #333;
}

.el-table th,
.el-table td {
  color: #333;
}

.el-pagination__total,
.el-pagination__sizes,
.el-pagination__prev,
.el-pagination__pager,
.el-pagination__next {
  color: #333;
}

.result-content {
  max-height: 300px;
  overflow-y: auto;
}

.load-more-trigger {
  height: 1px;
}

.result-description {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.view-json-button {
  margin-left: 10px;
}
.risk-tag {
  font-weight: bold;
  padding: 5px 10px;
}

.risk-high {
  background: linear-gradient(145deg, #ff3333, #ff0000) !important; /* 深红色渐变背景 */
  color: #ffffff !important; /* 白色文字 */
  font-size: 18px; /* 增大字体 */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  border: 1px solid #ff0000; /* 边框 */
  animation: blink 1.5s infinite; /* 背景闪烁特效 */
}

@keyframes blink {
  0%, 100% { background-color: #ff3333; }
  50% { background-color: #ff6666; }
}

.risk-medium {
  background: linear-gradient(145deg, #ffc107, #ff9800) !important; /* 保持橙色背景 */
  color: #ffffff !important; /* 白色文字 */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  border: 1px solid #ff9800; /* 边框 */
}

.risk-low {
  background: linear-gradient(145deg, #a5d6a7, #81c784) !important; /* 淡绿色渐变背景 */
  color: #ffffff !important; /* 白色文字 */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  border: 1px solid #81c784; /* 边框 */
}

.risk-unknown {
  background: linear-gradient(145deg, #ff4d4d, #ff6666) !important; /* 浅红色渐变背景 */
  color: #ffffff !important; /* 白色文字 */
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px; /* 圆角边框 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* 阴影效果 */
  border: 1px solid #ff4d4d; /* 边框 */
}

</style>

