<template>
    <div class="json-viewer">
      <el-loading :loading="loading" text="正在加载数据..." spinner="el-icon-loading">
        <div class="header">
          <h1>AWVS JSON 文件内容</h1>
        </div>
        <div v-if="!loading && jsonData">
          <p v-if="description" class="description">提醒：{{ description }}</p>
          <div class="actions">
            <el-button type="primary" @click="toggleJson">{{ showJson ? '隐藏 JSON' : '显示 JSON' }}</el-button>
            <el-button type="primary" @click="expandAll">展开所有</el-button>
            <el-button type="primary" @click="collapseAll">收缩所有</el-button>
          </div>
          <el-table v-if="showJson" :data="formattedJsonData" border style="width: 100%">
            <el-table-column prop="key" label="Key" width="180"></el-table-column>
            <el-table-column label="Value">
              <template v-slot="scope">
                <pre v-html="scope.row.value"></pre>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-alert title="没有数据可显示" type="info"></el-alert>
        </div>
      </el-loading>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'AwvsViewer',
    data() {
      return {
        jsonData: null,
        formattedJsonData: [],
        loading: true,
        showJson: false,
        description: ''
      };
    },
    created() {
      this.fetchJsonData();
    },
    methods: {
      async fetchJsonData() {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        const description = params.get('description');
        if (description) {
          this.description = decodeURIComponent(description);
        }
  
        if (!id) {
          this.loading = false;
          return;
        }
  
        try {
          const response = await axios.get(`https://manage.onenike.com/api/get_awvs_data.php?id=${id}`);
          this.jsonData = response.data;
          this.formatJsonData();
        } catch (error) {
          console.error('Error fetching JSON data:', error);
        } finally {
          this.loading = false;
        }
      },
      toggleJson() {
        this.showJson = !this.showJson;
      },
      expandAll() {
        this.showJson = true;
      },
      collapseAll() {
        this.showJson = false;
      },
      formatJsonData() {
        const formatted = [];
        for (const [key, value] of Object.entries(this.jsonData.json)) {
          formatted.push({
            key: key,
            value: this.formatValue(value)
          });
        }
        this.formattedJsonData = formatted;
      },
      formatValue(value) {
        if (typeof value === 'object' && value !== null) {
          let formattedValue = JSON.stringify(value, null, 2);
          if (value.vt_name) {
            const vtNameRegex = new RegExp(`("vt_name":\\s*")(${value.vt_name})(")`, 'g');
            formattedValue = formattedValue.replace(vtNameRegex, `$1<span style="color:red; font-weight:bold;">$2</span>$3`);
          }
          return formattedValue;
        }
        return value;
      }
    }
  };
  </script>
  
  <style scoped>
  .json-viewer {
    padding: 20px;
  }
  .description {
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  .actions {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  }
  </style>
  