<template>
    <div class="risk-level-manager">
      <el-card class="box-card">
        <template v-slot:header>
          <div class="clearfix">
            <span>风险等级管理</span>
            <el-button style="float: right;" type="primary" @click="showAddDialog">添加风险等级</el-button>
          </div>
        </template>
        <el-table :data="riskLevels" style="width: 100%">
          <el-table-column prop="type" label="模块类型"></el-table-column>
          <el-table-column prop="vulnerability_type" label="漏洞类型"></el-table-column>
          <el-table-column prop="risk_level" label="风险等级"></el-table-column>
          <el-table-column label="操作">
            <template v-slot:default="scope">
              <el-button size="mini" type="primary" @click="showEditDialog(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" @click="deleteRiskLevel(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
  
      <el-dialog v-model="dialogVisible" title="编辑风险等级">
        <el-form :model="currentRiskLevel" label-width="120px">
          <el-form-item label="模块类型">
            <el-select v-model="currentRiskLevel.type" placeholder="请选择模块类型">
              <el-option
                v-for="module in modules"
                :key="module.id"
                :label="module.name"
                :value="module.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="包含内容">
            <el-input v-model="currentRiskLevel.vulnerability_type"></el-input>
          </el-form-item>
          <el-form-item label="风险等级">
            <el-select v-model="currentRiskLevel.risk_level" placeholder="请选择风险等级">
              <el-option label="未知" value="unknown"></el-option>
              <el-option label="高危" value="high"></el-option>
              <el-option label="中危" value="medium"></el-option>
              <el-option label="低危" value="low"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template v-slot:footer>
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveRiskLevel">保存</el-button>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { ElMessage } from 'element-plus';
  import axios from 'axios';
  
  export default {
    name: 'RiskLevelManager',
    setup() {
      const riskLevels = ref([]);
      const modules = ref([]);
      const dialogVisible = ref(false);
      const currentRiskLevel = ref({
        id: null,
        type: '',
        vulnerability_type: '',
        risk_level: 'unknown'
      });
  
      const fetchRiskLevels = async () => {
        try {
          const response = await axios.get('https://manage.onenike.com/api/get_vulnerability_risk_levels.php');
          riskLevels.value = response.data;
        } catch (error) {
          console.error('Failed to fetch risk levels:', error);
          ElMessage.error('获取风险等级失败');
        }
      };
  
      const fetchModules = async () => {
        try {
          const response = await axios.get('https://manage.onenike.com/api/get_modules.php');
          modules.value = response.data;
        } catch (error) {
          console.error('Failed to fetch modules:', error);
          ElMessage.error('获取模块信息失败');
        }
      };
  
      const showAddDialog = () => {
        currentRiskLevel.value = {
          id: null,
          type: '',
          vulnerability_type: '',
          risk_level: 'unknown'
        };
        dialogVisible.value = true;
      };
  
      const showEditDialog = (riskLevel) => {
        currentRiskLevel.value = { ...riskLevel };
        dialogVisible.value = true;
      };
  
      const saveRiskLevel = async () => {
        try {
          if (currentRiskLevel.value.id) {
            await axios.post('https://manage.onenike.com/api/update_vulnerability_risk_level.php', currentRiskLevel.value);
            ElMessage.success('风险等级更新成功');
          } else {
            await axios.post('https://manage.onenike.com/api/create_vulnerability_risk_level.php', currentRiskLevel.value);
            ElMessage.success('风险等级添加成功');
          }
          dialogVisible.value = false;
          fetchRiskLevels();
        } catch (error) {
          console.error('Failed to save risk level:', error);
          ElMessage.error('保存风险等级失败');
        }
      };
  
      const deleteRiskLevel = async (id) => {
        try {
          await axios.post(`https://manage.onenike.com/api/delete_vulnerability_risk_level.php`, { id });
          ElMessage.success('风险等级删除成功');
          fetchRiskLevels();
        } catch (error) {
          console.error('Failed to delete risk level:', error);
          ElMessage.error('删除风险等级失败');
        }
      };
  
      onMounted(() => {
        fetchRiskLevels();
        fetchModules();
      });
  
      return {
        riskLevels,
        modules,
        dialogVisible,
        currentRiskLevel,
        showAddDialog,
        showEditDialog,
        saveRiskLevel,
        deleteRiskLevel
      };
    }
  };
  </script>
  
  <style scoped>
  .risk-level-manager {
    padding: 20px;
  }
  </style>
  