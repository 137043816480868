<template>
  <el-container class="layout-container">
    <el-aside :width="isCollapsed ? '64px' : '220px'" class="aside-container">
      <div class="logo" @click="toggleCollapse">{{ isCollapsed ? '骚' : '分布式扫描系统' }}</div>
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        background-color="#1f2d3d"
        text-color="#fff"
        active-text-color="#ffd04b"
        :collapse="isCollapsed"
      >
        <el-menu-item index="/home">
          <el-tooltip content="首页" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/home">
              <el-icon>{{ isCollapsed ? '首' : '' }}<i class="el-icon-house"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/home" v-else>
            <el-icon><i class="el-icon-house"></i></el-icon>
            <span>首页</span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index="/modules">
          <el-tooltip content="模块管理" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/modules">
              <el-icon>{{ isCollapsed ? '模' : '' }}<i class="el-icon-s-operation"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/modules" v-else>
            <el-icon><i class="el-icon-s-operation"></i></el-icon>
            <span>模块管理</span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index="/scanvul">
          <el-tooltip content="扫描结果(带提示)" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/scanvul">
              <el-icon>{{ isCollapsed ? '果': '' }}<i class="el-icon-document"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/scanvul" v-else>
            <el-icon><i class="el-icon-document"></i></el-icon>
            <span>扫描结果(带提示)</span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index="/scanresults">
          <el-tooltip content="扫描结果" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/scanresults">
              <el-icon>{{ isCollapsed ? '结' : '' }}<i class="el-icon-document"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/scanresults" v-else>
            <el-icon><i class="el-icon-document"></i></el-icon>
            <span>扫描结果</span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index="/urlmanage">
          <el-tooltip content="URL 管理" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/urlmanage">
              <el-icon>{{ isCollapsed ? 'URL' : '' }}<i class="el-icon-link"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/urlmanage" v-else>
            <el-icon><i class="el-icon-link"></i></el-icon>
            <span>URL 管理</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/Client">
          <el-tooltip content="渗透机查看" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/Client">
              <el-icon>{{ isCollapsed ? 'URL' : '' }}<i class="el-icon-link"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/Client" v-else>
            <el-icon><i class="el-icon-link"></i></el-icon>
            <span>渗透机查看</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/weapon-download">
          <el-tooltip content="武器下载" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/weapon-download">
              <el-icon>{{ isCollapsed ? '武器' : '' }}<i class="el-icon-download"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/weapon-download" v-else>
            <el-icon><i class="el-icon-download"></i></el-icon>
            <span>武器下载</span>
          </router-link>
        </el-menu-item>
        
        <el-menu-item index="/risklevelmanager">
          <el-tooltip content="风控管理" placement="right" effect="dark" v-if="isCollapsed">
            <router-link to="/risklevelmanager">
              <el-icon>{{ isCollapsed ? '风控' : '' }}<i class="el-icon-warning"></i></el-icon>
            </router-link>
          </el-tooltip>
          <router-link to="/risklevelmanager" v-else>
            <el-icon><i class="el-icon-warning"></i></el-icon>
            <span>风控管理</span>
          </router-link>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-main class="main-container">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'CaidanView',
  data() {
    return {
      isCollapsed: false
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  },
  computed: {
    breadcrumbs() {
      const route = this.$route;
      const breadcrumbs = [];
      route.matched.forEach(match => {
        if (match.name) {
          breadcrumbs.push({ name: match.meta.breadcrumb || match.name, path: match.path });
        }
      });
      return breadcrumbs;
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  color: #333;
}

.layout-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.aside-container {
  background-color: #1f2d3d;
  color: #fff;
}

.logo {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #ffd04b;
  background: #1a2a3b;
  margin-bottom: 20px;
  border-bottom: 1px solid #304156;
  cursor: pointer;
  transition: width 0.3s, font-size 0.3s;
}

.el-menu {
  border-right: none;
}

.el-menu-item {
  padding: 0 20px;
}

.el-menu-item a {
  display: flex;
  align-items: center;
  height: 56px;
  color: inherit;
  text-decoration: none;
  font-size: 16px;
}

.el-menu-item i {
  margin-right: 10px;
}

.el-menu-item:hover, .el-menu-item.is-active {
  background-color: #304156;
}

.header-container {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;
}

.main-container {
  padding: 20px;
  background: #f5f7fa;
  overflow-y: auto;
}

.el-breadcrumb {
  line-height: 60px;
}

.el-breadcrumb-item {
  font-size: 14px;
}
</style>
