<template>
  <div class="login-container">
    <el-card class="box-card">
      <h2>系统登录</h2>
      <el-form :model="loginForm" class="login-form">
        <el-form-item>
          <el-input
            v-model="loginForm.username"
            placeholder="用户名"
            @focus="focusInput"
            @blur="blurInput"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="loginForm.password"
            placeholder="密码"
            type="password"
            @focus="focusInput"
            @blur="blurInput"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="handleLogin" :loading="isLoading" class="login-button">
          登录
        </el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { encrypt } from '../utils/encryption'; // 加密工具

export default {
  name: 'LoginPage',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      isLoading: false
    };
  },
  methods: {
    handleLogin() {
      this.isLoading = true; // 显示加载动画
      const encryptedPassword = encrypt(this.loginForm.password);
      axios.post('https://manage.onenike.com/login.php', {
        username: this.loginForm.username,
        password: encryptedPassword
      }).then(response => {
        this.isLoading = false; // 隐藏加载动画
        if (response.data.success) {
          localStorage.setItem('token', response.data.token);
          this.$router.push('/home');
        } else {
          this.$message.error('登录失败');
        }
      }).catch(error => {
        this.isLoading = false; // 隐藏加载动画
        console.error(error);
        this.$message.error('登录请求出错');
      });
    },
    focusInput(event) {
      event.target.parentElement.classList.add('focus');
    },
    blurInput(event) {
      event.target.parentElement.classList.remove('focus');
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  height: 100%;
  background: #2c3e50; /* 深色背景 */
  color: #ecf0f1; /* 浅色文字 */
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.box-card {
  width: 400px;
  background: #34495e; /* 登录框背景颜色 */
  color: #ecf0f1;
  border: 1px solid #16a085;
  border-radius: 10px;
  box-shadow: 0 0 20px #16a085;
  text-align: center;
}

.login-form {
  margin-top: 20px;
}

.el-input__inner {
  background: #2c3e50;
  color: #ecf0f1;
  border: 1px solid #16a085;
}

.el-input__inner:focus {
  border-color: #16a085;
}

.el-button--primary {
  background: #16a085;
  border: none;
  color: #ecf0f1;
  width: 100%;
}

.el-button--primary:hover {
  background: #1abc9c;
}

.el-icon-user, .el-icon-lock {
  color: #16a085;
}

.login-button {
  margin-top: 20px;
}

.focus .el-input__inner {
  border-color: #16a085;
}
</style>
