import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import LoginPage from '../components/LoginPage.vue';
import HomeView from '../components/Home.vue';
import ModulesView from '../components/Modules.vue';
import ScanResultsUrlView from '../components/ScanResultsView.vue';
import ScanResultsView from '../components/ScanResults.vue';
import URLManageView from '../components/URLManage.vue';
import ClienView  from '../components/ClientView.vue';
import CaidanView from '@/components/Caidan.vue';
import WeaponDownloadView from '@/components/WeaponDownloadView.vue';
import VulnerabilityDetails from '@/components/VulnerabilityDetails.vue';
import RiskLevelManager from '@/components/RiskLevelManager.vue';
import ResuultsWithVul from '@/components/ResuultsWithVul.vue';
import JsonViewer from '@/components/JsonViewer.vue';
import AwvsView from '@/components/AwvsView.vue';
 
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: LoginPage,
    meta: { title: '莆田濯英中学保安大队 登录' }
  },
  {
    path: '/json-viewer',
    name: 'JsonViewer',
    component: JsonViewer,
    meta: { title: '漏洞查看 - 分布式扫描系统' }
  },
  {
    path: '/awvs-viewer',
    name: 'AwvsView',
    component: AwvsView,
    meta: { title: 'AWVS漏洞查看 - 分布式扫描系统' }
  },
  {
    path: '/',
    component: CaidanView,
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: { title: '首页 - 分布式扫描系统' }
      }, 
      {
        path: 'modules',
        name: 'modules',
        component: ModulesView,
        meta: { title: '模块管理 - 分布式扫描系统' }
      },
      {
        path: 'scanvul',
        name: 'ResuultsWithVul',
        component: ResuultsWithVul,
        meta: { title: '扫描结果(带提示) - 分布式扫描系统' }
      },
      {
        path: 'scanresults',
        name: 'scanresults',
        component: ScanResultsView,
        meta: { title: '扫描结果 - 分布式扫描系统' }
      },
      {
        path: 'urlmanage',
        name: 'urlmanage',
        component: URLManageView,
        meta: { title: 'URL管理 - 分布式扫描系统' }
      },
      {
        path: '/scanresults/:scan_id',
        name: 'ScanResults',
        component: ScanResultsUrlView,
        meta: { title: '网址扫描结果 - 分布式扫描系统' }
      },
      {
        path: '/weapon-download',
        name: 'WeaponDownload',
        component: WeaponDownloadView,
        meta: { title: '武器下载 - 分布式扫描系统' }
      },
      {
        path: '/vulnerability/:type',
        name: 'VulnerabilityDetails',
        component: VulnerabilityDetails,
        meta: { title: '模块所有漏洞查看 - 分布式扫描系统' }
      },
      {
        path: '/Client',
        name: 'Client',
        component: ClienView,
        meta: { title: '客户端查看 - 分布式扫描系统' }
      },
      {
        path: '/risklevelmanager',
        name: 'RiskLevelManage',
        component: RiskLevelManager,
        meta: { title: '漏洞等级 - 分布式扫描系统' }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwt");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    try {
      const response = await axios.post('https://manage.onenike.com/verify-token.php', { token });
      if (response.data.valid) {
        next();
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiry');
        if (to.path !== '/login') {
          next('/login');
        } else {
          next();
        }
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiry');
      if (to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    }
  } else {
    if (to.path !== '/login') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;
