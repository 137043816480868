<template>
  <div class="json-viewer">
    <!-- 全局加载提示 -->
    <el-loading :loading="loading" text="正在加载数据..." spinner="el-icon-loading">
      <div class="header">
        <h1>JSON 文件内容</h1>
      </div>

      <!-- 全部展开/收缩按钮 -->
      <div class="actions">
        <el-button type="primary" @click="toggleAll(true)">{{ expandAllText }}</el-button>
        <el-button type="primary" @click="toggleAll(false)">{{ collapseAllText }}</el-button>
      </div>

      <!-- 显示 JSON 数据 -->
      <div v-if="!loading && jsonData.length">
        <el-card v-for="(item, index) in jsonData" :key="index" class="json-item">
          <div class="toggle-header" @click="toggleCollapse(index)">
            {{ item.plugin }}
            <el-icon :icon="collapsedIndices.includes(index) ? 'el-icon-plus' : 'el-icon-minus'" />
          </div>
          <el-collapse-transition>
            <div v-show="!collapsedIndices.includes(index)" class="details">
              <p><strong>地址:</strong> {{ item.detail.addr }}</p>
              <el-table :data="item.detail.snapshot">
                <el-table-column prop="0" label="请求" v-slot="{ row }">
                  <div v-html="formatText(row[0])"></div>
                </el-table-column>
                <el-table-column prop="1" label="响应" v-slot="{ row }">
                  <div v-html="formatText(row[1])"></div>
                </el-table-column>
              </el-table>
            </div>
          </el-collapse-transition>
        </el-card>
      </div>

      <!-- 没有数据时的提示 -->
      <div v-else>
        <el-alert title="没有数据可显示" type="info"></el-alert>
      </div>
    </el-loading>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'JsonViewer',
  data() {
    return {
      jsonData: [], // 存储解析的 JSON 数据
      collapsedIndices: [], // 存储收缩的索引
      loading: true, // 控制全局加载状态
      expandAll: true, // 控制是否展开所有项
    };
  },
  computed: {
    expandAllText() {
      return '展开所有';
    },
    collapseAllText() {
      return '收缩所有';
    },
  },
  created() {
    const url = this.$route.query.url;
    if (url) {
      this.fetchJsonContent(url);
    }
  },
  methods: {
    async fetchJsonContent(url) {
      try {
        const response = await axios.get(url);
        const lines = response.data.split('\n');
        this.jsonData = lines
          .map(line => {
            try {
              return JSON.parse(line);
            } catch (error) {
              console.error('解析 JSON 失败:', error);
              return null;
            }
          })
          .filter(item => item !== null);
        this.collapsedIndices = []; // 初始化展开所有项
      } catch (error) {
        console.error('获取 JSON 文件内容失败:', error);
      } finally {
        this.loading = false; // 数据加载完成，隐藏加载提示
      }
    },
    toggleCollapse(index) {
      const idx = this.collapsedIndices.indexOf(index);
      if (idx === -1) {
        // 添加到收缩列表
        this.collapsedIndices.push(index);
      } else {
        // 从收缩列表中移除
        this.collapsedIndices.splice(idx, 1);
      }
    },
    toggleAll(expand) {
      if (expand) {
        this.collapsedIndices = []; // 展开所有项
      } else {
        this.collapsedIndices = this.jsonData.map((_, index) => index); // 收缩所有项
      }
    },
    formatText(text) {
      return text
        .replace(/\\r\\n|\\r|\\n/g, '<br>') // 替换换行符
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // 替换制表符
        .replace(/</g, '&lt;') // 替换小于号
        .replace(/>/g, '&gt;') // 替换大于号
        .replace(/"/g, '&quot;') // 替换双引号
        .replace(/'/g, '&#39;'); // 替换单引号
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5; /* 白色背景 */
  color: #333; /* 深色文本 */
}

.json-viewer {
  padding: 20px;
}

.header {
  background: #0e0e0e;
  color: #0f0;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.actions {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.json-item {
  background-color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.toggle-header {
  cursor: pointer;
  margin: 0;
  padding: 10px;
  background-color: #e0e0e0;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details {
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

td {
  white-space: pre-wrap; /* 允许换行 */
}
</style>
