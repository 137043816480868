<template>
    <div class="scan-results-container">
      <div class="header">
        <h1>{{ type }} 漏洞详情</h1>
      </div>
      <div class="content">
        <el-table :data="vulnerabilities.data" style="width: 100%">
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="module" label="模块"></el-table-column>
          <el-table-column label="结果">
            <template v-slot="scope">
              <div v-if="scope.row.module === 'goon'">
                <div class="goon-content">
                    <p v-html="formatGoonContent(scope.row.result)"></p>
                  </div>
              </div>
              <div v-else-if="isJson(scope.row.result)">
                <el-popover placement="top" width="300" trigger="click">
                  <template #reference>
                    <el-button type="primary">点击查看详情</el-button>
                  </template>
                  <div class="json-content">
                    <div v-for="(item, index) in parseJson(scope.row.result)" :key="index">
                      <p>{{ item }}</p>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div v-else-if="isDownloadLink(scope.row.result)">
                <el-button type="primary" @click="downloadFile(scope.row.result)">下载</el-button>
              </div>
              <div v-else>
                {{ scope.row.result }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="report_file" label="报告文件"></el-table-column>
          <el-table-column prop="created_at" label="创建时间"></el-table-column>
        </el-table>
        <el-pagination
          v-if="vulnerabilities.total > resultsPerPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="resultsPerPage"
          layout="total, sizes, prev, pager, next"
          :total="vulnerabilities.total">
        </el-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  
  export default {
    name: 'VulnerabilityDetails',
    setup() {
      const route = useRoute();
      const type = ref(route.params.type);
      const vulnerabilities = ref({ data: [], total: 0 });
      const currentPage = ref(1);
      const resultsPerPage = ref(10);
  
      onMounted(() => {
        fetchVulnerabilities(currentPage.value, resultsPerPage.value);
      });
  
      const fetchVulnerabilities = (page, perPage) => {
        axios
          .get(`https://manage.onenike.com/api/get_vulnerabilities.php?type=${type.value}&page=${page}&per_page=${perPage}`)
          .then((response) => {
            vulnerabilities.value = response.data;
          })
          .catch((error) => {
            console.error('Error fetching vulnerabilities:', error);
          });
      };
  
      const handleSizeChange = (size) => {
        resultsPerPage.value = size;
        fetchVulnerabilities(currentPage.value, resultsPerPage.value);
      };
  
      const handleCurrentChange = (page) => {
        currentPage.value = page;
        fetchVulnerabilities(currentPage.value, resultsPerPage.value);
      };
  
      const isJson = (result) => {
        try {
          JSON.parse(result);
          return true;
        } catch (e) {
          return false;
        }
      };
  
      const parseJson = (result) => {
        return JSON.parse(result);
      };
  
      const isDownloadLink = (result) => {
        return result.startsWith('https://manage.onenike.com/api/uploaded_files/');
      };
  
      const downloadFile = (url) => {
        window.open(url, '_blank');
      };
  
      const formatGoonContent = (content) => {
        const lines = content.split('------------------------------------');
        return lines.map(line => {
          const trimmedLine = line.trim();
          if (!trimmedLine) return '';
          if (trimmedLine.startsWith('port')) return `<strong>端口</strong>: ${trimmedLine.replace('port', '').trim()}`;
          if (trimmedLine.startsWith('url')) return `<strong>URL</strong>: ${trimmedLine.replace('url', '').trim()}`;
          if (trimmedLine.startsWith('title')) return `<strong>标题</strong>: ${trimmedLine.replace('title', '').trim()}`;
          if (trimmedLine.startsWith('finger')) return `<strong>指纹</strong>: ${trimmedLine.replace('finger', '').trim()}`;
          if (trimmedLine.startsWith('ftp')) return `<strong>FTP</strong>: ${trimmedLine.replace('ftp', '').trim()}`;
          if (trimmedLine.startsWith('ssh')) return `<strong>SSH</strong>: ${trimmedLine.replace('ssh', '').trim()}`;
          return trimmedLine;
        }).join('<br/>');
      };
  
      return {
        type,
        vulnerabilities,
        currentPage,
        resultsPerPage,
        fetchVulnerabilities,
        handleSizeChange,
        handleCurrentChange,
        isJson,
        parseJson,
        isDownloadLink,
        downloadFile,
        formatGoonContent,
      };
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
  
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    height: 100%;
    overflow: hidden;
    background-color: #f5f5f5; /* 白色背景 */
    color: #333; /* 深色文本 */
  }
  
  .scan-results-container {
    padding: 20px;
  }
  
  .header {
    background: #0e0e0e;
    color: #0f0;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  .json-content, .goon-content {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .goon-content p {
    white-space: pre-wrap;
    word-break: break-word;
  }
  </style>
  