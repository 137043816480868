<template>
    <div class="weapon-download-container">
      <div class="header">
        <h1>武器下载</h1>
      </div>
      <div class="content">
        <h2>下载</h2>
        <el-row>
          <el-col :span="12">
            <el-card>
              <h3>编译完的文件压缩包</h3>
              <el-link type="primary" :href="compiledFileLink" target="_blank">下载编译文件</el-link>
              <p>上传日期: {{ compiledFileDate }}</p>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card>
              <h3>源代码压缩包</h3>
              <el-link type="primary" :href="sourceFileLink" target="_blank">下载源代码</el-link>
              <p>上传日期: {{ sourceFileDate }}</p>
            </el-card>
          </el-col>
        </el-row>
        <h2>上传</h2>
        <el-row>
          <el-col :span="12">
            <el-upload
              class="upload-demo"
              action="https://manage.onenike.com/api/upload.php?type=compiled"
              :on-success="handleUploadSuccess('compiled')"
              :on-error="handleUploadError"
              :file-list="compiledFileList"
              :limit="1"
              :multiple="false"
              :http-request="uploadRequest"
            >
              <el-button type="primary">上传编译文件</el-button>
            </el-upload>
          </el-col>
          <el-col :span="12">
            <el-upload
              class="upload-demo"
              action="https://manage.onenike.com/api/upload.php?type=source"
              :on-success="handleUploadSuccess('source')"
              :on-error="handleUploadError"
              :file-list="sourceFileList"
              :limit="1"
              :multiple="false"
              :http-request="uploadRequest"
            >
              <el-button type="primary">上传源代码</el-button>
            </el-upload>
          </el-col>
        </el-row>
        <h2>更新说明</h2>
        <el-input
          type="textarea"
          v-model="updateNotes"
          placeholder="请输入更新说明"
          rows="5"
        ></el-input>
        <el-button type="primary" @click="saveUpdateNotes">保存更新说明</el-button>
        <h3>最新更新说明</h3>
        <p>{{ latestUpdateNotes }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
 
  export default {
    name: 'WeaponDownloadView',
    data() {
      return {
        compiledFileLink: 'https://manage.onenike.com/api/files/compiled.zip',
        sourceFileLink: 'https://manage.onenike.com/api/files/source.zip',
        compiledFileDate: '',
        sourceFileDate: '',
        compiledFileList: [],
        sourceFileList: [],
        updateNotes: '',
        latestUpdateNotes: ''
      };
    },
    methods: {
      fetchFileDates() {
        axios.get('https://manage.onenike.com/api/getfiledates.php').then(response => {
          this.compiledFileDate = response.data.compiledFileDate;
          this.sourceFileDate = response.data.sourceFileDate;
          this.latestUpdateNotes = response.data.latestUpdateNotes;
        }).catch(error => {
          console.error('获取文件日期和更新说明失败:', error);
        });
      },
      handleUploadSuccess(type) {
        return (response, file, fileList) => {
          this.$message.success(`${file.name} 上传成功`);
          if (type === 'compiled') {
            this.compiledFileList = fileList;
            this.compiledFileDate = response.uploadDate;
          } else {
            this.sourceFileList = fileList;
            this.sourceFileDate = response.uploadDate;
          }
        };
      },
      handleUploadError(err, file, fileList) {
        this.$message.error(`${file.name} 上传失败`);
        if (fileList === this.compiledFileList) {
          this.compiledFileList = fileList;
        } else {
          this.sourceFileList = fileList;
        }
      },
      uploadRequest(options) {
        const formData = new FormData();
        formData.append(options.filename, options.file);
        axios.post(options.action, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            options.onProgress({
              percent: Math.round((progressEvent.loaded * 100) / progressEvent.total)
            });
          }
        }).then(response => {
          options.onSuccess(response.data, options.file);
        }).catch(error => {
          options.onError(error);
        });
      },
      saveUpdateNotes() {
        axios.post('https://manage.onenike.com/api/saveupdatenotes.php', {
          updateNotes: this.updateNotes
        }).then(() => {
          this.$message.success('更新说明保存成功');
          this.latestUpdateNotes = this.updateNotes;
          this.updateNotes = '';
        }).catch(error => {
          this.$message.error('更新说明保存失败');
          console.error('更新说明保存失败:', error);
        });
      }
    },
    mounted() {
      this.fetchFileDates();
    }
  };
  </script>
  
  <style scoped>
  .weapon-download-container {
    padding: 20px;
  }
  
  .header {
    background: #0e0e0e;
    color: #0f0;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .content {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  .el-upload {
    margin-top: 20px;
  }
  </style>
  