<template>
  <div class="scan-results-container">
    <el-card class="box-card">
      <template #header>
        <div class="header">
          <el-icon><el-icon-platform-eleme /></el-icon>
          <span>扫描结果</span>
        </div>
      </template>
      <div class="content">
        <el-form inline class="demo-form-inline">
          <el-form-item>
            <el-input v-model="searchQuery" placeholder="搜索任务网址" clearable @keyup.enter="fetchResults"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="fetchResults">搜索</el-button>
          </el-form-item>
        </el-form>

        <el-table
          :data="results"
          @sort-change="handleSortChange"
          v-loading="loading"
          element-loading-spinner="el-icon-loading"
          style="width: 100%;"
          stripe
          border
        >
          <el-table-column prop="id" label="ID" width="50"></el-table-column>
          <el-table-column prop="url" label="对应任务的网址" width="250"></el-table-column>
          <el-table-column prop="module" label="模块" width="100"></el-table-column>
          <el-table-column label="结果">
            <template #default="scope">
              <div v-if="scope.row.module === 'goon'">
                <el-popover placement="top" width="300" trigger="click">
                  <template #reference>
                    <el-button type="primary" size="mini">点击查看详情</el-button>
                  </template>
                  <div class="goon-content">
                    <p v-html="formatGoonContent(scope.row.result)"></p>
                  </div>
                </el-popover>
              </div>
              <div v-else-if="isJson(scope.row.result)">
                <el-popover placement="top" width="300" trigger="click">
                  <template #reference>
                    <el-button type="primary" size="mini">点击查看详情</el-button>
                  </template>
                  <div class="json-content">
                    <div v-for="(item, index) in parseJson(scope.row.result)" :key="index">
                      <p>{{ item }}</p>
                    </div>
                  </div>
                </el-popover>
              </div>
              <div v-else-if="isDownloadLink(scope.row.result) === 'html'">
                <el-button type="primary" size="mini" @click="downloadFile(scope.row.result)">打开</el-button>
              </div>
              <div v-else-if="isDownloadLink(scope.row.result) === 'json'">
                <el-button type="primary" size="mini" @click="viewJson(scope.row.result)">查看</el-button>
              </div>
              <div v-else>
                <el-popover placement="top" width="300" trigger="click">
                  <template #reference>
                    <el-button type="primary" size="mini">点击实时查看详情</el-button>
                  </template>
                  <div class="result-content">
                    <p>{{ scope.row.result }}</p>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="vulnerability_type" label="漏洞类型" width="120"></el-table-column>
          <el-table-column prop="risk_level" label="漏洞危害等级" width="120">
            <template #default="scope">
              <el-tag v-if="scope.row.risk_level === 'unknown'" type="danger">未知</el-tag>
              <el-tag v-else-if="scope.row.risk_level === 'high'" type="danger">高危</el-tag>
              <el-tag v-else-if="scope.row.risk_level === 'medium'" type="warning">中危</el-tag>
              <el-tag v-else-if="scope.row.risk_level === 'low'" type="success">低危</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="report_file" label="报告文件" width="120"></el-table-column>
          <el-table-column prop="created_at" label="创建时间" sortable width="180"></el-table-column>
        </el-table>
        
        <el-pagination
          @size-change="handleResultsSizeChange"
          @current-change="fetchResults"
          :current-page="resultsPage"
          :page-size="resultsPerPage"
          layout="total, sizes, prev, pager, next"
          :total="resultsTotal"
          :page-sizes="[10, 20, 30, 40]"
          background
          class="pagination"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';

export default {
  name: 'ScanResultsView',
  data() {
    return {
      results: [],
      resultsTotal: 0,
      resultsPage: 1,
      resultsPerPage: 10,
      resultsSortField: 'created_at',
      resultsSortOrder: 'DESC',
      searchQuery: '',
      loading: null,
    };
  },
  created() {
    this.fetchResults(this.resultsPage, this.resultsPerPage, this.resultsSortField, this.resultsSortOrder);
  },
  methods: {
    fetchResults(page = 1, perPage = 10, sortField = 'created_at', sortOrder = 'DESC') {
      this.loading = ElLoading.service({
        lock: true,
        customClass: 'custom-loading-spinner',
        spinner: 'el-icon-loading',
        text: '加载中，请稍候...',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      axios.get(`https://manage.onenike.com/api/result_with_vul.php`, {
        params: {
          page,
          per_page: perPage,
          sort_field: sortField,
          sort_order: sortOrder,
          search: this.searchQuery
        }
      }).then(response => {
        this.results = response.data.data;
        this.resultsTotal = response.data.total;
        this.resultsPage = response.data.page;
        this.resultsPerPage = response.data.per_page;
        this.loading.close();
      }).catch(error => {
        console.error(error);
        ElMessage.error('获取扫描结果失败');
        this.loading.close();
      });
    },
    handleResultsSizeChange(size) {
      this.resultsPerPage = size;
      this.fetchResults(this.resultsPage, this.resultsPerPage, this.resultsSortField, this.resultsSortOrder);
    },
    handleSortChange({ prop, order }) {
      this.resultsSortField = prop;
      this.resultsSortOrder = order === 'ascending' ? 'ASC' : 'DESC';
      this.fetchResults(this.resultsPage, this.resultsPerPage, this.resultsSortField, this.resultsSortOrder);
    },
    isJson(result) {
      try {
        JSON.parse(result);
        return true;
      } catch (e) {
        return false;
      }
    },
    parseJson(result) {
      return JSON.parse(result);
    },
    isDownloadLink(result) {
      if (result.startsWith('https://manage.onenike.com/api/uploaded_files/')) {
        return result.endsWith('.json') ? 'json' : 'html';
      }
      return false;
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    viewJson(url) {
      window.open(`/json-viewer?url=${encodeURIComponent(url)}`, '_blank');
    },
    formatGoonContent(content) {
      const lines = content.split('------------------------------------');
      return lines.map(line => {
        const trimmedLine = line.trim();
        if (!trimmedLine) return '';
        if (trimmedLine.startsWith('port')) return `<strong>端口</strong>: ${trimmedLine.replace('port', '').trim()}`;
        if (trimmedLine.startsWith('url')) return `<strong>URL</strong>: ${trimmedLine.replace('url', '').trim()}`;
        if (trimmedLine.startsWith('title')) return `<strong>标题</strong>: ${trimmedLine.replace('title', '').trim()}`;
        if (trimmedLine.startsWith('finger')) return `<strong>指纹</strong>: ${trimmedLine.replace('finger', '').trim()}`;
        if (trimmedLine.startsWith('ftp')) return `<strong>FTP</strong>: ${trimmedLine.replace('ftp', '').trim()}`;
        if (trimmedLine.startsWith('ssh')) return `<strong>SSH</strong>: ${trimmedLine.replace('ssh', '').trim()}`;
        return trimmedLine;
      }).join('<br/>');
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  overflow: hidden;
}

.scan-results-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.header {
  background: #1a2a6c;
  color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 10px;
}

.content {
  background: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.box-card {
  width: 100%;
}

.demo-form-inline {
  margin-bottom: 20px;
  text-align: center;
}

.json-content, .goon-content {
  max-height: 400px;
  overflow-y: auto;
}

.goon-content p {
  white-space: pre-wrap;
  word-break: break-word;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

/* 自定义加载样式 */
.custom-loading-spinner .el-loading-spinner {
  position: relative;
  width: 60px;
  height: 60px;
}

.custom-loading-spinner .el-loading-spinner::before,
.custom-loading-spinner .el-loading-spinner::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: 3px solid transparent;
  border-radius: 50%;
}

.custom-loading-spinner .el-loading-spinner::before {
  border-top-color: #409eff;
  border-bottom-color: #409eff;
  animation: rotate 1s infinite linear;
}

.custom-loading-spinner .el-loading-spinner::after {
  border-left-color: #409eff;
  border-right-color: #409eff;
  animation: rotate 0.5s infinite linear reverse;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
