<template>
  <el-card class="box-card" :shadow="isCollapsed ? 'never' : 'always'">
    <template v-slot:header>
      <el-row @click="toggleCollapse" style="cursor: pointer;">
        <el-col :span="22">
          <span>系统监控数据</span>
        </el-col>
        <el-col :span="2" style="text-align: right;">
          <el-icon :class="{ 'rotate-icon': isCollapsed }">
            <el-icon-arrow-down />
          </el-icon>
        </el-col>
      </el-row>
    </template>
    <el-collapse-transition>
      <el-row :gutter="20" v-show="!isCollapsed">
        <el-col :span="24">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="systemData.length"
            :page-size="pageSize"
            @current-change="handlePageChange"
          />
          <!-- 每两个 machine 为一行 -->
          <el-row :gutter="20" v-for="(rowMachines, rowIndex) in paginatedRows" :key="rowIndex" style="margin-bottom: 20px;">
            <el-col v-for="(machine, index) in rowMachines" :key="index" :span="12">
              <el-card shadow="hover">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-descriptions :title="'系统信息 - ' + (currentPage - 1) * pageSize + (rowIndex * 2 + index + 1)" column="2">
                      <el-descriptions-item label="主机名称">{{ machine?.hostname }}</el-descriptions-item>
                      <el-descriptions-item label="主机内网IP">{{ machine?.hostIP }}</el-descriptions-item>
                      <el-descriptions-item label="主机外网IP">{{ machine?.publicip }}</el-descriptions-item>
                      <el-descriptions-item label="CPU 核心数">{{ machine?.cpuCount }}</el-descriptions-item>
                      <el-descriptions-item label="总内存">{{ machine?.memorySize }}</el-descriptions-item>
                      <el-descriptions-item label="内存使用率">
                        <el-progress :percentage="parseFloat(machine?.memoryUsage)" color="#67c23a"></el-progress>
                      </el-descriptions-item>
                      <el-descriptions-item label="硬盘总大小">{{ machine?.diskSize }}</el-descriptions-item>
                      <el-descriptions-item label="已用磁盘空间">{{ machine?.usedDiskSpace }}</el-descriptions-item>
                      <el-descriptions-item label="磁盘使用率">
                        <el-progress :percentage="parseFloat(machine?.diskUsage)" color="#409eff"></el-progress>
                      </el-descriptions-item>
                      <el-descriptions-item label="CPU 使用率">
                        <el-progress :percentage="parseFloat(machine?.cpuUsage)" color="#f56c6c"></el-progress>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-col>
                  <el-col :span="24">
                    <el-table :data="machine?.networkUsage" style="width: 100%">
                      <el-table-column prop="interface" label="网络接口" width="150"></el-table-column>
                      <el-table-column prop="bytesSent" label="发送速率 (KB/s)" width="180"></el-table-column>
                      <el-table-column prop="bytesReceived" label="接收速率 (KB/s)" width="180"></el-table-column>
                      <el-table-column prop="totalBytesSent" label="发送总量 (MB)" width="200"></el-table-column>
                      <el-table-column prop="totalBytesReceived" label="接收总量 (MB)" width="200"></el-table-column>
                    </el-table>
                  </el-col>
                  <!-- Last Work CMD界面 -->
                  <el-col :span="24">
                    <el-card class="cmd-card" shadow="hover">
                      <template v-slot:header>
                        <div>
                          <span>最后操作记录</span>
                        </div>
                      </template>
                      <div class="cmd-output-container">
                        <transition-group name="cmd-scroll" tag="div" class="cmd-output-scroll">
                          <div v-for="work in getDisplayedWorks(machine)" :key="work.id" class="work-entry">
                            <span class="timestamp">[{{ work.time }}]</span> {{ work.operation }}
                          </div>
                        </transition-group>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-collapse-transition>
  </el-card>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { ElMessage } from 'element-plus';

export default {
  name: 'ClientView',
  setup() {
    const systemData = ref([]);
    const currentPage = ref(1);
    const pageSize = 5;
    let ws = null;
    const isCollapsed = ref(false);

    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * pageSize;
      const end = start + pageSize;
      return systemData.value.slice(start, end);
    });

    const paginatedRows = computed(() => {
      const rows = [];
      for (let i = 0; i < paginatedData.value.length; i += 2) {
        rows.push(paginatedData.value.slice(i, i + 2));
      }
      return rows;
    });

    const toggleCollapse = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    const connectWebSocket = () => {
      ws = new WebSocket('wss://manage.onenike.com/wss');
      ws.onopen = () => {
        ElMessage.success('WebSocket 连接已建立');
      };
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        const networkUsage = data.NetworkInterfaces.map((iface) => ({
          interface: iface.Interface,
          bytesSent: (iface.BytesSentPerSecond / 1024).toFixed(2),
          bytesReceived: (iface.BytesReceivedPerSecond / 1024).toFixed(2),
          totalBytesSent: iface.TotalBytesSent.toFixed(2),
          totalBytesReceived: iface.TotalBytesReceived.toFixed(2),
        }));

        const machineData = {
          hostIP: data.HostIP,
          hostname: data.HostName,
          publicip: data.PublicIP,
          cpuCount: data.CPUCount,
          memorySize: data.MemorySize,
          uptime: data.Uptime,
          cpuUsage: data.CPUUsage.replace('%%', '%'),
          memoryUsage: data.MemoryUsage.replace('%%', '%'),
          diskSize: data.DiskSize,
          usedDiskSpace: data.UsedDiskSpace,
          diskUsage: data.DiskUsage.replace('%%', '%'),
          lastWork: [],
          networkUsage: networkUsage,
        };

        const machineIndex = systemData.value.findIndex(m => m.hostIP === machineData.hostIP);
        if (machineIndex > -1) {
          const existingMachine = systemData.value[machineIndex];
          Object.assign(existingMachine, machineData);
          existingMachine.lastWork.push({
            id: Date.now(),
            time: new Date().toLocaleString(),
            operation: data.LastWork
          });
          if (existingMachine.lastWork.length > 10) {
            existingMachine.lastWork.shift();
          }
        } else {
          machineData.lastWork.push({
            id: Date.now(),
            time: new Date().toLocaleString(),
            operation: data.LastWork
          });
          systemData.value.push(machineData);
        }
      };
      ws.onerror = (error) => {
        ElMessage.error('WebSocket 发生错误');
        console.error('WebSocket 错误:', error);
      };
      ws.onclose = () => {
        ElMessage.warning('WebSocket 连接关闭，正在重连...');
        setTimeout(connectWebSocket, 1000);
      };
    };

    const handlePageChange = (page) => {
      currentPage.value = page;
    };

    const getDisplayedWorks = (machine) => {
      return machine?.lastWork || [];
    };

    onMounted(() => {
      connectWebSocket();
    });

    onBeforeUnmount(() => {
      if (ws) {
        ws.close();
      }
    });

    return {
      systemData,
      paginatedData,
      paginatedRows,
      handlePageChange,
      currentPage,
      pageSize,
      isCollapsed,
      toggleCollapse,
      getDisplayedWorks,
    };
  }
};
</script>

<style scoped>
.box-card {
  margin: 20px;
}

.cmd-card {
  margin-top: 10px;
}

.cmd-output-container {
  height: 200px;
  overflow: hidden;
}

.cmd-output-scroll {
  background-color: black;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  padding: 10px;
  border-radius: 5px;
  height: calc(1.3em * 6); /* 假设每行高度为1.2em */
  overflow-y: auto;
  white-space: pre-wrap; /* 保持换行 */
}

.cmd-scroll-enter-active,
.cmd-scroll-leave-active {
  transition: all 0.5s ease;
}

.cmd-scroll-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.cmd-scroll-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.work-entry {
  padding: 5px 0;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.timestamp {
  color: #888;
  margin-right: 10px;
}
</style>